<template>
  <div class="list_page2">
    <div class="box_con">
      <div class="box_list_wrap">
        <div class="box_shadow m_8">
          <div class="box box_one">
            <div
              class="box_list_wrap"
              v-for="(item, index) in items"
              :key="index"
              @click="fnPage(item.res.resNo)"
            >
              <div class="box_list">
                <div class="list_ttl">
                  <div class="left">
                    <div class="m_4">
                      <span class="build_name">{{ buildingName }}</span>
                    </div>
                    <p>{{ item.res.title }}</p>
                    <div class="price_box">
                      <span class="price_txt">{{
                        $dayjs(item.res.regDate).format('YYYY.MM.DD')
                      }}</span>
                    </div>
                  </div>
                  <div class="middle_box flex1none">
                    <div class="middle">
                      <p class="price r_12 deposit">{{ item.unit }}</p>
                    </div>
                    <div class="icon"></div>
                  </div>
                </div>
              </div>
              <div class="line_grey none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'tenant-notice-li-list',
  description: '입주민 소통공간 리스트',
  props: {
    items: {
      type: [Array],
      default: () => {}
    },
    buildingName: {
      type: String,
      default: ''
    }
  },
  setup() {
    const router = useRouter()

    const fnPage = item => {
      router.push({ path: '/menu/notice/tenant/view', query: { resNo: item } })
    }
    return { fnPage }
  }
}
</script>

<style scoped></style>
