<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(items)">
          <tenant-notice-li-list :items="items" :building-name="buildingName" />
          <div v-observe-visibility="visibilityChanged"></div>
        </div>
        <empty-index v-else :content="'알림사항이 없습니다.'" />
        <div class="page_btn">
          <div class="btn_wrap">
            <button class="btn on" @click="fnPage">글 쓰기</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import TenantNoticeLiList from '@/components/views/tenant-notice/li-list'
import EmptyIndex from '@/components/views/empty/full'
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'menu-notice-tenant-index',
  description: '입주민 소통공간 리스트 페이지',
  components: { EmptyIndex, TenantNoticeLiList },
  setup() {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const router = useRouter()

    const userData = computed(() => store.getters['user/getData'])

    const state = reactive({
      buildingName: userData.value.buildingName,
      items: [],
      pageInfo: [],
      params: {
        page: 1,
        limit: 10
      }
    })

    const fnListBox = async () => {
      const res = await proxy.$ResNoticeSvc.fetchUserResNoticeList(state.params)
      if (res.code === 1) {
        state.items = state.items.concat(res.entity.list)
        state.pageInfo = res.entity.pageInfo
        state.params.page++
      }
    }
    const visibilityChanged = async isVisible => {
      if (isVisible) {
        if (state.pageInfo.totalPage < state.params.page) return false
        await fnListBox()
      }
    }

    const fnPage = () => {
      router.push({ path: '/menu/notice/tenant/write' })
    }

    fnListBox()
    return { ...toRefs(state), fnPage, fnListBox, visibilityChanged }
  }
}
</script>

<style scoped></style>
